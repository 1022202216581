<template>
  <div class="order-after-check">
    <div class="contentDiv">
      <el-table
        ref="multipleTable"
        border
        height="800"
        :data="tableData"
        tooltip-effect="dark"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column prop="hd_id" label="客户id" width="80" align="center" />
        <!--        <el-table-column prop="hd_name" label="门店" width="150" align="center" />-->
        <el-table-column prop="after_item" label="申请订单号" width="180" align="center" />
        <el-table-column prop="quantity" label="商品数" width="80" align="center" />
        <el-table-column label="申请金额" prop="total" />
        <el-table-column label="审核金额" prop="after_total_money" />
        <el-table-column label="售后原因" align="center">
          <template v-slot="{ row }">
            <i v-if="row.order_pack_type === 0">质量问题</i>
            <i v-if="row.order_pack_type === 1">物流破损</i>
          </template>
        </el-table-column>
        <el-table-column label="申请时间" align="center" width="180">
          <template v-slot="{ row }">
            {{ row.created_at | formatDateStr }}
          </template>
        </el-table-column>

        <el-table-column label="订单状态" align="center">
          <template v-slot="{ row }">
            <span v-if="row.status === 0">待审核</span>
            <span v-if="row.status === 1">部分通过</span>
            <span v-if="row.status === 2">全部通过</span>
            <span v-if="row.status === 3">全部拒绝</span>
          </template>
        </el-table-column>
        <el-table-column label="当前审核节点">
          <template v-slot:="{row}">
            <span v-if="row.approval_status === 1">客服审核</span>
            <span v-if="row.approval_status === 2">A岗审核</span>
            <span v-if="row.approval_status === 3">营销中心负责人审核</span>
            <span v-if="row.approval_status === 4">总裁审核</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="300">
          <template v-slot="{ row }">
            <el-button type="primary" size="mini" @click="handleGetOrderAfterInfo(row)"
            >审批
            </el-button>
            <!--            <el-button @click="handleCheckOrderAfter(row,2)" type="success" plain>审核通过</el-button>-->
            <!--            <el-button @click="handleCheckOrderAfter(row,3)" type="warning" plain>审核拒绝</el-button>-->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    <el-drawer size="75%" :with-header="false" :direction="drawerStatus.direction">-->
    <!--      <div class="afterHeader">售后详情</div>-->
    <!--      <div style="padding: 10px; border: 1px solid #218da0; margin: 3px">-->
    <!--        <ul>-->
    <!--          <li>客户ID：{{ afterSaleInfo.OrderAfter.hd_id }}</li>-->
    <!--          <li>数量：{{ afterSaleInfo.OrderAfter.quantity }}</li>-->
    <!--          <li>客服：server</li>-->
    <!--          <li>提交金额：{{ afterSaleInfo.OrderAfter.total }}</li>-->
    <!--          <li>通过金额：{{ afterSaleInfo.OrderAfter.after_total_money }}</li>-->
    <!--          <li>-->
    <!--            理由：-->
    <!--            <span v-if="afterSaleInfo.OrderAfter.order_pack_type === 0">质量问题</span>-->
    <!--            <span v-if="afterSaleInfo.OrderAfter.order_pack_type === 1">物流破损</span>-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            时间：{{ afterSaleInfo.OrderAfter.created_at | formatDateStr }}-->
    <!--          </li>-->
    <!--          <li>-->
    <!--            当前状态：-->
    <!--            <span v-if="afterSaleInfo.OrderAfter.status === 0">待审核</span>-->
    <!--            <span v-if="afterSaleInfo.OrderAfter.status === 1">部分通过</span>-->
    <!--            <span v-if="afterSaleInfo.OrderAfter.status === 2">全部通过</span>-->
    <!--            <span v-if="afterSaleInfo.OrderAfter.status === 3">全部拒绝</span>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </div>-->
    <!--      <div class="divTitle">商品信息</div>-->
    <!--      <div style="padding: 5px">-->
    <!--        <el-table-->
    <!--          :data="afterSaleInfo.OrderGoodsAfter"-->
    <!--          height="500"-->
    <!--          border-->
    <!--          style="width: 100%">-->
    <!--          <el-table-column-->
    <!--            prop="goods_code"-->
    <!--            label="条码"-->
    <!--            width="120"-->
    <!--            align="center">-->
    <!--          </el-table-column>-->
    <!--          <el-table-column-->
    <!--            prop="name"-->
    <!--            label="图片"-->
    <!--            width="180"-->
    <!--            align="center">-->
    <!--            <template v-slot="{ row }">-->
    <!--              <viewer>-->
    <!--                <img-->
    <!--                  height="60"-->
    <!--                  :src="`http://hwimg.xmvogue.com/thumb/${row.goods_code}.jpg?x-oss-process=style/440`"-->
    <!--                  alt="" />-->
    <!--              </viewer>-->
    <!--            </template>-->
    <!--          </el-table-column>-->

    <!--          <el-table-column prop="num" label="数量" align="center">-->
    <!--          </el-table-column>-->
    <!--          <el-table-column prop="price" label="单价" align="center" />-->
    <!--          <el-table-column label="总价" align="center">-->
    <!--            <template v-slot="{row}">-->
    <!--              <span>{{ (row.num * (row.price * 1000)) / 1000 }}  </span>-->
    <!--            </template>-->
    <!--          </el-table-column>-->
    <!--          <el-table-column prop="reason" label="理由" align="center">-->
    <!--          </el-table-column>-->
    <!--          <el-table-column prop="address" label="上传面单" align="center">-->
    <!--            <template v-slot="scope">-->
    <!--              <viewer>-->
    <!--                <img-->
    <!--                  :src="item"-->
    <!--                  height="60"-->
    <!--                  v-for="(item, index) in scope.row.imagesArr"-->
    <!--                  :key="index"-->
    <!--                  alt="" />-->
    <!--              </viewer>-->
    <!--            </template>-->
    <!--          </el-table-column>-->
    <!--          <el-table-column prop="address" label="商品图片" align="center">-->
    <!--            <template v-slot="scope">-->
    <!--              <viewer>-->
    <!--                <img-->
    <!--                  :src="item"-->
    <!--                  height="60"-->
    <!--                  v-for="(item, index) in scope.row.goodsImgArr"-->
    <!--                  :key="index"-->
    <!--                  alt="" />-->
    <!--              </viewer>-->
    <!--            </template>-->
    <!--          </el-table-column>-->
    <!--          <el-table-column prop="address" label="状态" align="center">-->
    <!--            <template v-slot="{ row }">-->
    <!--              <span v-if="row.status === 0">待审核</span>-->
    <!--              <span v-if="row.status === 1">拒绝</span>-->
    <!--              <span v-if="row.status === 2">同意</span>-->
    <!--            </template>-->
    <!--          </el-table-column>-->
    <!--          <el-table-column prop="remarks" label="备注" align="center" width="180"></el-table-column>-->
    <!--          <el-table-column label="操作" align="center" width="180">-->
    <!--            <template v-slot="{ row }">-->
    <!--              <p v-if="row.status > 0">已处理</p>-->
    <!--            </template>-->
    <!--          </el-table-column>-->
    <!--        </el-table>-->
    <!--      </div>-->
    <!--    </el-drawer>-->

    <el-dialog :visible.sync="drawerStatus.drawer" width="60%">
      <div>
        <el-form label-width="120px">
          <el-form-item label="备注">
            <el-input type="textarea" :row="8" v-model="remarks"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button @click="handleCheckOrderAfter(2)" type="success" plain>审核通过</el-button>
            <el-button @click="handleCheckOrderAfter(3)" type="warning" plain>审核拒绝</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="afterSaleInfo.OrderGoodsAfter" height="500" border style="width: 100%">
        <el-table-column
          prop="goods_code"
          label="条码"
          width="120"
          align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="图片"
          width="80"
          align="center">
          <template v-slot="{ row }">
            <el-image
              v-if="row.is_presell ===1"
              height="60"
              :src="row.presell_goods_img"
              :preview-src-list="[row.presell_goods_img]"
              :z-index="9999"
              alt="" />
              <el-image
              v-else
              height="60"
              :src="`http://hwimg.xmvogue.com/thumb/${row.goods_code}.jpg?x-oss-process=style/440`"
              :preview-src-list="[`http://hwimg.xmvogue.com/thumb/${row.goods_code}.jpg?x-oss-process=style/440`]"
              :z-index="9999"
              alt="" />

          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" align="center" />
        <el-table-column prop="price" label="单价" align="center" width="80" />
        <el-table-column label="总价" align="center" width="80">
          <template v-slot="{row}">
            <span>{{ (row.num * (row.price * 1000)) / 1000 }}  </span>
          </template>
        </el-table-column>
        <el-table-column prop="reason" label="理由" align="center">
        </el-table-column>
        <el-table-column prop="address" label="上传面单" align="center">
          <template v-slot="scope">
            <el-image
              :src="item"
              height="60"
              v-for="(item, index) in scope.row.imagesArr"
              :key="index"
              :z-index="9999"
              :preview-src-list="[item]"
              alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="address" label="商品图片" align="center">
          <template v-slot="scope">
            <el-image
              :src="item"
              height="60"
              v-for="(item, index) in scope.row.goodsImgArr"
              :key="index"
              :z-index="9999"
              :preview-src-list="[item]"
              alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="address" label="状态" align="center">
          <template v-slot="{ row }">
            <span v-if="row.status === 0">待审核</span>
            <span v-if="row.status === 1">拒绝</span>
            <span v-if="row.status === 2">同意</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" align="center"/>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <p v-if="row.status > 0">已处理</p>
            <el-button v-if="row.status === 2" type="danger" size="mini" @click="setAfterGoodsStatus(row, 1)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

  </div>
</template>

<script>
import common from "@/common/mixins/common.js";

export default {
  name: "index",
  mixins: [common],
  data() {
    return {
      tableData: [],
      pageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
      afterSaleInfo: {
        OrderAfter: {},
        OrderGoodsAfter: [],
        OrderAfterLog: {},
      },
      drawerStatus: {
        drawer: false,
        direction: "rtl",
      },
      afterSaleInformation :{},
      remarks:''
    };
  },
  created() {
    this.handleGetOrderAfterCheck();
  },
  methods: {
    handleGetOrderAfterCheck() {
      const queryData={
        ...this.pageInfo,
      };
      this.$axios.get("/a1/order/afterSale/check", { params: queryData }).then((res) => {
        const { data }=res.data;
        this.tableData=data.data;
        this.pageInfo.total=data.total;
      });
    },
    handleGetOrderAfterInfo(row) {
      this.afterSaleInformation=row;
      this.$axios.get("/a1/order/afterSale/" + row.id).then((res) => {
        this.afterSaleInfo.OrderAfter=res.data.data.OrderAfter;
        let orderGoodsAfter=res.data.data.OrderGoodsAfter;
        for (let i=0; i < orderGoodsAfter.length; i++) {
          orderGoodsAfter[i].imagesArr=orderGoodsAfter[i].images.split(",");
          orderGoodsAfter[i].goodsImgArr=
            orderGoodsAfter[i].goods_img.split(",");
        }
        this.afterSaleInfo.OrderGoodsAfter=orderGoodsAfter;
        this.afterSaleInfoStatus=res.data.data.OrderAfter.status + "";
        this.drawerStatus.drawer=true;

        this.afterSaleInfo.OrderAfterLog=res.data.data.OrderAfterLog;

      });

    },
    handleCheckOrderAfter(type) {

      if (this.afterSaleInformation.status === 0) {
        this.$message.info("存在未审核商品，请先审核商品。当前审核操作无法审核商品")
        return
      }

      this.$axios.post("/a1/order/afterSale/check",
        { id: this.afterSaleInformation.id, type: type ,remarks:this.remarks}).
      then(() => {
        this.$message.success("提交成功");
        this.handleGetOrderAfterCheck();
        window.location.reload()
      });
    },
    setAfterGoodsStatus(afterGoods, status) {
      this.$prompt("请输入备注", "备注").then(({ value }) => {

        this.$axios
          .put("/a1/order/afterGoodsStatus/" + afterGoods.id + "/" + status,
            { id: afterGoods.id, status: status, remarks: value })
          .then(() => {
            this.$message.success("设置成功");
            //this.afterSaleInfo.OrderAfter.status = status
            this.getInfo(this.afterSaleInformation);
          });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "操作已取消",
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
/* 头部 */
.afterHeader {
  background: #218da0;
  color: white;
  padding: 10px;
}

.divTitle {
  background: #218da0;
  padding: 8px;
  color: white;
}
</style>
